import React, { useState, useEffect } from 'react'
import { 
  Users, Search, Filter, MoreVertical, Shield, Ban, 
  UserCheck, UserX, Mail, Loader2, AlertTriangle, User, UserMinus, UserPlus 
} from 'lucide-react'
import { 
  collection, query, getDocs, doc, updateDoc, 
  where, orderBy, limit, startAfter, 
  serverTimestamp, DocumentData, setDoc, deleteDoc 
} from 'firebase/firestore'
import { db, auth } from '../../firebase'
import { UserData } from '../../types'
import toast from 'react-hot-toast'

export function UserManagement() {
  const [users, setUsers] = useState<UserData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterRole, setFilterRole] = useState<'all' | 'admin' | 'user'>('all')
  const [filterStatus, setFilterStatus] = useState<'all' | 'active' | 'blocked'>('all')

  useEffect(() => {
    loadUsers()
  }, [filterRole, filterStatus])

  const loadUsers = async () => {
    setIsLoading(true)
    try {
      let usersQuery = query(
        collection(db, 'users'),
        orderBy('createdAt', 'desc')
      )

      const snapshot = await getDocs(usersQuery)
      const userData = snapshot.docs.map(doc => ({
        ...doc.data(),
        uid: doc.id
      })) as UserData[]

      setUsers(userData)
    } catch (error) {
      console.error('Error loading users:', error)
      toast.error('Failed to load users')
    } finally {
      setIsLoading(false)
    }
  }

  const toggleUserRole = async (userId: string, makeAdmin: boolean) => {
    try {
      const userRef = doc(db, 'users', userId)
      const adminRef = doc(db, 'admins', userId)

      if (makeAdmin) {
        // Make user an admin
        await setDoc(adminRef, {
          addedBy: auth.currentUser?.email,
          addedAt: serverTimestamp(),
          isAdmin: true
        })
        await updateDoc(userRef, { isAdmin: true })
      } else {
        // Remove admin status
        await deleteDoc(adminRef)
        await updateDoc(userRef, { isAdmin: false })
      }

      // Refresh user list
      await loadUsers()
      toast.success(`User ${makeAdmin ? 'promoted to' : 'removed from'} admin`)
    } catch (error) {
      console.error('Error toggling user role:', error)
      toast.error('Failed to update user role')
    }
  }

  return (
    <div className="space-y-6">
      {/* Search and Filters */}
      <div className="flex flex-col sm:flex-row gap-4">
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-1 bg-gray-700 rounded-lg px-4 py-2 text-white"
        />
        <select
          value={filterRole}
          onChange={(e) => setFilterRole(e.target.value as any)}
          className="bg-gray-700 rounded-lg px-4 py-2 text-white"
        >
          <option value="all">All Roles</option>
          <option value="admin">Admins</option>
          <option value="user">Users</option>
        </select>
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value as any)}
          className="bg-gray-700 rounded-lg px-4 py-2 text-white"
        >
          <option value="all">All Status</option>
          <option value="active">Active</option>
          <option value="blocked">Blocked</option>
        </select>
      </div>

      {/* Users List */}
      {isLoading ? (
        <div className="flex justify-center py-8">
          <Loader2 className="w-8 h-8 text-emerald-500 animate-spin" />
        </div>
      ) : (
        <div className="space-y-4">
          {users.map((user) => (
            <div
              key={user.uid}
              className="bg-gray-700/30 rounded-lg p-4 flex items-center justify-between"
            >
              <div className="flex items-center gap-4">
                {user.photoURL ? (
                  <img
                    src={user.photoURL}
                    alt={user.displayName}
                    className="w-10 h-10 rounded-full"
                  />
                ) : (
                  <div className="w-10 h-10 rounded-full bg-gray-600 flex items-center justify-center">
                    <User size={20} className="text-gray-400" />
                  </div>
                )}
                <div>
                  <div className="font-medium text-white">{user.displayName}</div>
                  <div className="text-sm text-gray-400">{user.email}</div>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <span className={`px-2 py-1 rounded-full text-xs font-medium
                  ${user.isAdmin ? 
                    'bg-emerald-500/10 text-emerald-400 border border-emerald-500/20' : 
                    'bg-gray-700/50 text-gray-400 border border-gray-600/20'}`}
                >
                  {user.isAdmin ? 'Admin' : 'User'}
                </span>
                <button
                  onClick={() => toggleUserRole(user.uid, !user.isAdmin)}
                  className="text-gray-400 hover:text-white"
                >
                  {user.isAdmin ? <UserMinus size={20} /> : <UserPlus size={20} />}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default UserManagement 