import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  onAuthStateChanged, 
  setPersistence, 
  browserLocalPersistence,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import { 
  getFirestore, 
  doc, 
  setDoc, 
  getDoc,
  collection,
  query,
  getDocs,
  DocumentData,
  initializeFirestore,
  persistentLocalCache,
  CACHE_SIZE_UNLIMITED,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import toast from 'react-hot-toast';
import { UserData, LoginFormValues, UserFormValues } from './types';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: "ets-wordle.firebaseapp.com",
  projectId: "ets-wordle",
  storageBucket: "ets-wordle.appspot.com",
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Wait for auth to be ready
let authReady = false;
let currentUser = null;

// Configure auth persistence
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error("Auth persistence error:", error);
});

// Initialize Firestore with offline persistence
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  })
});

// Initialize Firebase Storage with custom settings
export const storage = getStorage(app, "gs://ets-wordle.firebasestorage.app");

// Helper function to wait for auth
async function waitForAuth(): Promise<void> {
  if (authReady) return;
  
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      authReady = true;
      currentUser = user;
      unsubscribe();
      resolve();
    });
  });
}

// Auth functions
export const firebaseSignIn = async ({ email, password }: LoginFormValues) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    await waitForAuth(); // Wait for auth to be ready
    await initializeUser(result.user); // Initialize user data
    return result;
  } catch (error: any) {
    console.error('Sign in error:', error);
    if (error.code === 'auth/wrong-password') {
      throw new Error('Invalid password');
    } else if (error.code === 'auth/too-many-requests') {
      throw new Error('Too many attempts. Please try again later');
    }
    throw error;
  }
};

export const firebaseSignUp = async ({ email, password, displayName }: UserFormValues) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    if (result.user) {
      await initializeUser({
        ...result.user,
        displayName
      });
    }
    return result;
  } catch (error: any) {
    console.error('Sign up error:', error);
    if (error.code === 'auth/email-already-in-use') {
      throw new Error('Email already in use');
    }
    throw error;
  }
};

export const firebaseSignOut = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error('Sign out error:', error);
    throw error;
  }
};

// Helper function to check if user is admin
export async function isUserAdmin(user: any): Promise<boolean> {
  if (!user?.uid) return false;
  
  try {
    // First check if user has admin email
    if (user.email === 'gwatts@engineeredtaxservices.com') {
      return true;
    }

    // Then check admin collection
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (userDoc.exists() && userDoc.data()?.isAdmin) {
      return true;
    }

    return false;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}

// Helper function to initialize or update user data
export async function initializeUser(user: any): Promise<void> {
  if (!user?.uid) return;

  await waitForAuth(); // Wait for auth to be ready
  const userRef = doc(db, 'users', user.uid);
  
  try {
    const userDoc = await getDoc(userRef);
    const isAdminEmail = user.email === 'gwatts@engineeredtaxservices.com';
    
    if (!userDoc.exists()) {
      // Create new user document with all fields
      const userData: Partial<UserData> = {
        uid: user.uid,
        email: user.email || '',
        displayName: user.displayName || user.email?.split('@')[0] || 'Anonymous',
        isAdmin: isAdminEmail,
        photoURL: user.photoURL || '',
        department: '',
        company: '',  // Initialize company field
        phone: '',
        location: '',
        notifications: true,
        darkMode: false,
        soundEffects: true,
        status: 'active',
        createdAt: new Date(),
        updatedAt: new Date(),
        lastLogin: new Date()
      };

      await setDoc(userRef, userData);
      
      // If user is admin, create admin document
      if (isAdminEmail) {
        const adminRef = doc(db, 'admins', user.uid);
        await setDoc(adminRef, {
          email: user.email,
          addedAt: serverTimestamp(),
          addedBy: 'system'
        });
      }
      
      console.log('New user document created');
    } else {
      // Update existing user document while preserving existing fields
      const existingData = userDoc.data();
      const updateData: any = {
        updatedAt: new Date(),
        lastLogin: new Date(),
        isAdmin: isAdminEmail,
        status: existingData.status || 'active'
      };

      // Only update fields if they have new values, preserve existing values
      if (user.displayName) {
        updateData.displayName = user.displayName;
      }
      
      if (user.photoURL) {
        updateData.photoURL = user.photoURL;
      }

      // Preserve existing fields if they exist
      if (existingData.company) {
        updateData.company = existingData.company;
      }
      if (existingData.department) {
        updateData.department = existingData.department;
      }
      if (existingData.phone) {
        updateData.phone = existingData.phone;
      }
      if (existingData.location) {
        updateData.location = existingData.location;
      }
      if (typeof existingData.notifications !== 'undefined') {
        updateData.notifications = existingData.notifications;
      }
      if (typeof existingData.darkMode !== 'undefined') {
        updateData.darkMode = existingData.darkMode;
      }
      if (typeof existingData.soundEffects !== 'undefined') {
        updateData.soundEffects = existingData.soundEffects;
      }

      // Use merge: true to only update specified fields
      await setDoc(userRef, updateData, { merge: true });

      // If user is admin, ensure admin document exists
      if (isAdminEmail) {
        const adminRef = doc(db, 'admins', user.uid);
        const adminDoc = await getDoc(adminRef);
        if (!adminDoc.exists()) {
          await setDoc(adminRef, {
            email: user.email,
            addedAt: serverTimestamp(),
            addedBy: 'system'
          });
        }
      }
    }

    // Ensure scores document exists
    const scoreRef = doc(db, 'scores', user.uid);
    const scoreDoc = await getDoc(scoreRef);
    
    if (!scoreDoc.exists()) {
      await setDoc(scoreRef, {
        userId: user.uid,
        displayName: user.displayName || user.email?.split('@')[0] || 'Anonymous',
        photoURL: user.photoURL,
        totalGames: 0,
        gamesWon: 0,
        gamesLost: 0,
        averageGuesses: 0,
        currentStreak: 0,
        bestStreak: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        lastPlayed: null
      });
    }
  } catch (error: any) {
    console.error('Error initializing user:', error);
    // Don't show error toast for permission errors during initial setup
    if (!error.message.includes('permission-denied')) {
      toast.error('Error initializing user data');
    }
  }
}

// Helper function to get user data
export async function getUserData(uid: string): Promise<UserData | null> {
  if (!uid) return null;
  
  await waitForAuth(); // Wait for auth to be ready
  
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      const data = userDoc.data();
      return {
        ...data,
        createdAt: data.createdAt?.toDate(),
        updatedAt: data.updatedAt?.toDate(),
        lastLogin: data.lastLogin?.toDate()
      } as UserData;
    }
    return null;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching user data:', error.message);
      // Don't show error toast for permission errors
      if (!error.message.includes('permission-denied')) {
        toast.error('Error fetching user data');
      }
    } else {
      console.error('Unknown error:', error);
      toast.error('An unknown error occurred');
    }
    return null;
  }
}

// Initialize user on auth state change
onAuthStateChanged(auth, async (user) => {
  currentUser = user;
  if (user) {
    try {
      await initializeUser(user);
    } catch (error) {
      console.error('Error in auth state change:', error);
    }
  }
});

export default app;
