import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { auth, initializeUser } from './firebase';
import { Toaster } from 'react-hot-toast';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');

// Initialize user if already signed in
auth.onAuthStateChanged((user) => {
  console.log('Auth state changed:', user);
  if (user) {
    initializeUser(user).catch(console.error);
  }
});

createRoot(rootElement).render(
  <StrictMode>
    <App />
    <Toaster position="top-center" />
  </StrictMode>
);