import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { 
  X, Settings, Users, Mail, Globe, Plus, Trash2, 
  Save, AlertCircle, CheckCircle2, Loader2 
} from 'lucide-react'
import { 
  collection, query, getDocs, doc, setDoc, deleteDoc, 
  serverTimestamp, where, getDoc, updateDoc 
} from 'firebase/firestore'
import { db, auth } from '../firebase'
import { GameVariant } from '../types'
import { WORDS } from '../words'
import toast from 'react-hot-toast'

interface AdminConsoleProps {
  isOpen: boolean
  onClose: () => void
}

type Tab = 'variants' | 'admins' | 'access'

export function AdminConsole({ isOpen, onClose }: AdminConsoleProps) {
  const [activeTab, setActiveTab] = useState<Tab>('variants')
  const [isLoading, setIsLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [variants, setVariants] = useState<GameVariant[]>([])
  const [admins, setAdmins] = useState<string[]>([])
  const [allowedEmails, setAllowedEmails] = useState<string[]>([])
  const [allowedDomains, setAllowedDomains] = useState<string[]>([])
  const [newEmail, setNewEmail] = useState('')
  const [newDomain, setNewDomain] = useState('')
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const checkAdminAndLoad = async () => {
      const user = auth.currentUser
      if (!user) {
        setIsAdmin(false)
        setError('Not signed in')
        setIsLoading(false)
        return
      }

      if (user.email === 'gwatts@engineeredtaxservices.com') {
        try {
          // First ensure admin document exists
          const adminRef = doc(db, 'admins', user.uid)
          await setDoc(adminRef, {
            email: user.email,
            addedAt: serverTimestamp(),
            isAdmin: true
          }, { merge: true }) // Use merge to avoid overwriting existing data

          setIsAdmin(true)
          await loadData()
        } catch (error) {
          console.error('Error initializing admin console:', error)
          setError('Failed to initialize admin console')
        } finally {
          setIsLoading(false)
        }
      } else {
        setIsAdmin(false)
        setError('Unauthorized access')
        setIsLoading(false)
      }
    }

    if (isOpen) {
      checkAdminAndLoad()
    }
  }, [isOpen])

  const loadData = async () => {
    if (!isAdmin) return

    setIsLoading(true)
    setError(null)
    try {
      // Load collections data
      const [variantsSnapshot, adminsSnapshot, emailsSnapshot, domainsSnapshot] = await Promise.all([
        getDocs(collection(db, 'gameVariants')),
        getDocs(collection(db, 'admins')),
        getDocs(collection(db, 'allowedEmails')),
        getDocs(collection(db, 'allowedDomains'))
      ])

      setVariants(variantsSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      })) as GameVariant[])

      setAdmins(adminsSnapshot.docs.map(doc => doc.id))
      setAllowedEmails(emailsSnapshot.docs.map(doc => doc.id))
      setAllowedDomains(domainsSnapshot.docs.map(doc => doc.id))
    } catch (error) {
      console.error('Error loading admin data:', error)
      setError('Failed to load data')
    } finally {
      setIsLoading(false)
    }
  }

  const handleAddAdmin = async (email: string) => {
    try {
      // Get user by email
      const usersRef = collection(db, 'users')
      const q = query(usersRef, where('email', '==', email))
      const snapshot = await getDocs(q)
      
      if (snapshot.empty) {
        throw new Error('User not found. They must sign in at least once before being made an admin.')
      }

      const userId = snapshot.docs[0].id

      // Only create admin document - user document updates will be handled by a Cloud Function
      await setDoc(doc(db, 'admins', userId), {
        email,
        isAdmin: true,
        addedBy: auth.currentUser?.email,
        addedAt: serverTimestamp()
      })

      setAdmins([...admins, userId])
      toast.success('Admin added successfully')
    } catch (error: any) {
      console.error('Error adding admin:', error)
      toast.error(error.message || 'Failed to add admin')
    }
  }

  // Add remove admin function
  const handleRemoveAdmin = async (userId: string) => {
    try {
      // Only remove from admins collection - user document updates should be handled by a Cloud Function
      await deleteDoc(doc(db, 'admins', userId))

      setAdmins(admins.filter(id => id !== userId))
      toast.success('Admin removed successfully')
    } catch (error: any) {
      console.error('Error removing admin:', error)
      toast.error('Failed to remove admin')
    }
  }

  const handleAddAllowedEmail = async (email: string) => {
    try {
      await setDoc(doc(db, 'allowedEmails', email), {
        addedBy: auth.currentUser?.email,
        addedAt: serverTimestamp()
      })
      setAllowedEmails([...allowedEmails, email])
      setNewEmail('')
      toast.success('Email added successfully')
    } catch (error) {
      toast.error('Failed to add email')
    }
  }

  const handleAddAllowedDomain = async (domain: string) => {
    try {
      await setDoc(doc(db, 'allowedDomains', domain), {
        addedBy: auth.currentUser?.email,
        addedAt: serverTimestamp()
      })
      setAllowedDomains([...allowedDomains, domain])
      setNewDomain('')
      toast.success('Domain added successfully')
    } catch (error) {
      toast.error('Failed to add domain')
    }
  }

  const handleRemoveAllowedEmail = async (email: string) => {
    try {
      await deleteDoc(doc(db, 'allowedEmails', email))
      setAllowedEmails(allowedEmails.filter(e => e !== email))
      toast.success('Email removed successfully')
    } catch (error) {
      toast.error('Failed to remove email')
    }
  }

  const handleRemoveAllowedDomain = async (domain: string) => {
    try {
      await deleteDoc(doc(db, 'allowedDomains', domain))
      setAllowedDomains(allowedDomains.filter(d => d !== domain))
      toast.success('Domain removed successfully')
    } catch (error) {
      toast.error('Failed to remove domain')
    }
  }

  if (!isOpen || !isAdmin) return null

  return createPortal(
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm flex items-center justify-center p-4 z-[100]">
      <div className="bg-gray-800 dark:bg-black rounded-lg w-full max-w-4xl overflow-hidden border-2 border-emerald-500 shadow-[0_0_15px_rgba(16,185,129,0.3)]">
        {/* Header */}
        <div className="p-4 border-b border-gray-700 flex items-center justify-between">
          <h2 className="text-xl font-bold text-white flex items-center gap-2">
            <Settings className="text-emerald-400" />
            Admin Console
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-700">
          <nav className="flex">
            <button
              onClick={() => setActiveTab('variants')}
              className={`px-6 py-3 text-sm font-medium ${
                activeTab === 'variants'
                  ? 'text-emerald-400 border-b-2 border-emerald-400'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              Game Variants
            </button>
            <button
              onClick={() => setActiveTab('admins')}
              className={`px-6 py-3 text-sm font-medium ${
                activeTab === 'admins'
                  ? 'text-emerald-400 border-b-2 border-emerald-400'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              Admins
            </button>
            <button
              onClick={() => setActiveTab('access')}
              className={`px-6 py-3 text-sm font-medium ${
                activeTab === 'access'
                  ? 'text-emerald-400 border-b-2 border-emerald-400'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              Access Control
            </button>
          </nav>
        </div>

        {/* Content */}
        <div className="p-6">
          {isLoading ? (
            <div className="flex items-center justify-center py-12">
              <Loader2 className="w-8 h-8 text-emerald-500 animate-spin" />
            </div>
          ) : error ? (
            <div className="flex items-center justify-center py-12 text-red-400 gap-2">
              <AlertCircle />
              {error}
            </div>
          ) : (
            <div className="space-y-6">
              {activeTab === 'variants' && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-white">Game Variants</h3>
                  {/* Game variants management UI */}
                  <div className="text-gray-400">
                    Game variants management coming soon...
                  </div>
                </div>
              )}

              {activeTab === 'admins' && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-white">Manage Admins</h3>
                  <div className="space-y-4">
                    {admins.map(adminId => (
                      <div key={adminId} className="flex items-center justify-between p-3 bg-gray-700/30 rounded-lg">
                        <span className="text-white">{adminId}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {activeTab === 'access' && (
                <div className="space-y-6">
                  {/* Allowed Emails */}
                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-white flex items-center gap-2">
                      <Mail className="text-emerald-400" />
                      Allowed Emails
                    </h3>
                    <div className="flex gap-2">
                      <input
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        placeholder="Enter email address"
                        className="flex-1 bg-gray-700 rounded-lg px-4 py-2 text-white"
                      />
                      <button
                        onClick={() => handleAddAllowedEmail(newEmail)}
                        className="bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-emerald-700"
                      >
                        <Plus size={20} />
                      </button>
                    </div>
                    <div className="space-y-2">
                      {allowedEmails.map(email => (
                        <div key={email} className="flex items-center justify-between p-3 bg-gray-700/30 rounded-lg">
                          <span className="text-white">{email}</span>
                          <button
                            onClick={() => handleRemoveAllowedEmail(email)}
                            className="text-red-400 hover:text-red-300"
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Allowed Domains */}
                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-white flex items-center gap-2">
                      <Globe className="text-emerald-400" />
                      Allowed Domains
                    </h3>
                    <div className="flex gap-2">
                      <input
                        type="text"
                        value={newDomain}
                        onChange={(e) => setNewDomain(e.target.value)}
                        placeholder="Enter domain (e.g., engineeredtaxservices.com)"
                        className="flex-1 bg-gray-700 rounded-lg px-4 py-2 text-white"
                      />
                      <button
                        onClick={() => handleAddAllowedDomain(newDomain)}
                        className="bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-emerald-700"
                      >
                        <Plus size={20} />
                      </button>
                    </div>
                    <div className="space-y-2">
                      {allowedDomains.map(domain => (
                        <div key={domain} className="flex items-center justify-between p-3 bg-gray-700/30 rounded-lg">
                          <span className="text-white">{domain}</span>
                          <button
                            onClick={() => handleRemoveAllowedDomain(domain)}
                            className="text-red-400 hover:text-red-300"
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  )
}

export default AdminConsole