import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { X, Crown, Medal, Trophy, Award, Star, Building2, Target, Sparkles } from 'lucide-react'
import { collection, query, orderBy, limit, getDocs, where, Timestamp, DocumentData } from 'firebase/firestore'
import { db } from '../firebase'
import { Score } from '../types'
import { toast } from 'react-hot-toast'

interface LeaderboardProps {
  isOpen: boolean
  onClose: () => void
}

export function Leaderboard({ isOpen, onClose }: LeaderboardProps) {
  const [scores, setScores] = useState<Score[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [timeFrame, setTimeFrame] = useState<'all' | 'month' | 'week'>('all')

  useEffect(() => {
    const fetchScores = async () => {
      setIsLoading(true)
      setError(null)
      try {
        // Calculate date ranges for filtering
        const now = new Date()
        let startDate: Date | null = null
        
        if (timeFrame === 'week') {
          startDate = new Date(now)
          startDate.setDate(now.getDate() - 7)
        } else if (timeFrame === 'month') {
          startDate = new Date(now)
          startDate.setMonth(now.getMonth() - 1)
        }

        // Build query based on time frame
        let baseQuery
        if (startDate) {
          // For time-filtered queries
          baseQuery = query(
            collection(db, 'scores'),
            where('lastPlayed', '>=', startDate),
            orderBy('lastPlayed', 'desc'),
            orderBy('gamesWon', 'desc')
          )
        } else {
          // For all-time queries
          baseQuery = query(
            collection(db, 'scores'),
            orderBy('gamesWon', 'desc')
          )
        }

        const snapshot = await getDocs(baseQuery)
        
        // Get user details for each score
        const scoresWithDetails = await Promise.all(
          snapshot.docs.map(async doc => {
            const scoreData = doc.data()
            try {
              // Get user document to get company info
              const userDoc = await getDocs(query(
                collection(db, 'users'),
                where('uid', '==', scoreData.userId)
              ))
              const userData = userDoc.docs[0]?.data()
              
              // Convert Firestore timestamp to Date
              const lastPlayed = scoreData.lastPlayed instanceof Timestamp 
                ? scoreData.lastPlayed.toDate() 
                : new Date()

              return {
                ...scoreData,
                lastPlayed,
                company: userData?.company || 'Unknown',
                department: userData?.department || 'Unknown'
              } as Score
            } catch (err) {
              console.error('Error fetching user details:', err)
              return {
                ...scoreData,
                lastPlayed: scoreData.lastPlayed instanceof Timestamp 
                  ? scoreData.lastPlayed.toDate() 
                  : new Date(),
                company: 'Unknown',
                department: 'Unknown'
              } as Score
            }
          })
        )

        // Sort the results by additional criteria in memory
        const sortedScores = scoresWithDetails
          .sort((a, b) => {
            // First by gamesWon
            if (b.gamesWon !== a.gamesWon) return b.gamesWon - a.gamesWon
            // Then by bestStreak
            if (b.bestStreak !== a.bestStreak) return b.bestStreak - a.bestStreak
            // Finally by averageGuesses (ascending)
            return a.averageGuesses - b.averageGuesses
          })
          .slice(0, 20) // Take top 20 after sorting

        setScores(sortedScores)
      } catch (err) {
        console.error('Error fetching scores:', err)
        setError('Failed to load leaderboard')
        toast.error('Failed to load leaderboard')
      } finally {
        setIsLoading(false)
      }
    }

    if (isOpen) {
      fetchScores()
    }
  }, [isOpen, timeFrame])

  if (!isOpen) return null

  const getRankIcon = (index: number) => {
    switch (index) {
      case 0:
        return <Crown className="text-yellow-400" size={24} />
      case 1:
        return <Medal className="text-gray-400" size={24} />
      case 2:
        return <Medal className="text-amber-600" size={24} />
      default:
        return <Star className="text-emerald-400" size={24} />
    }
  }

  return createPortal(
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm flex items-center justify-center p-4 z-[100]">
      <div className="bg-gray-800 rounded-lg p-8 max-w-4xl w-full border-2 border-emerald-500 shadow-[0_0_15px_rgba(16,185,129,0.3)] animate-fade-in overflow-y-auto max-h-[90vh]">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white flex items-center gap-2">
            <Trophy className="text-emerald-500" />
            Tax Master Hall of Fame
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        {/* Time Frame Selector */}
        <div className="flex gap-2 mb-6">
          <button
            onClick={() => setTimeFrame('all')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              timeFrame === 'all'
                ? 'bg-emerald-500 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
          >
            All Time
          </button>
          <button
            onClick={() => setTimeFrame('month')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              timeFrame === 'month'
                ? 'bg-emerald-500 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
          >
            This Month
          </button>
          <button
            onClick={() => setTimeFrame('week')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              timeFrame === 'week'
                ? 'bg-emerald-500 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
          >
            This Week
          </button>
        </div>

        {isLoading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500" />
          </div>
        ) : error ? (
          <div className="text-center text-red-400 py-8">
            {error}
          </div>
        ) : (
          <div className="space-y-4">
            {scores.map((score, index) => (
              <div 
                key={score.userId}
                className="bg-gray-700/50 rounded-lg p-4 hover:bg-gray-700/70 transition-colors"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className="flex items-center justify-center w-12">
                      {getRankIcon(index)}
                    </div>
                    <div>
                      <div className="font-medium text-white text-lg">
                        {score.displayName}
                      </div>
                      <div className="flex items-center gap-2 text-sm text-gray-400">
                        <Building2 size={14} />
                        {score.company || 'Unknown'}
                        {score.department && ` • ${score.department}`}
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-emerald-400 font-medium">
                      {((score.gamesWon / score.totalGames) * 100).toFixed(1)}% Win Rate
                    </div>
                    <div className="text-sm text-gray-400">
                      Best Streak: {score.bestStreak}
                    </div>
                  </div>
                </div>
                
                {/* Detailed Stats */}
                <div className="mt-3 grid grid-cols-4 gap-4 pt-3 border-t border-gray-600/50">
                  <div className="text-center">
                    <div className="text-gray-400 text-sm flex items-center justify-center gap-1">
                      <Target size={14} />
                      Games Won
                    </div>
                    <div className="text-white font-medium">
                      {score.gamesWon} / {score.totalGames}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-gray-400 text-sm flex items-center justify-center gap-1">
                      <Award size={14} />
                      Avg. Guesses
                    </div>
                    <div className="text-white font-medium">
                      {score.averageGuesses.toFixed(1)}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-gray-400 text-sm flex items-center justify-center gap-1">
                      <Sparkles size={14} />
                      Current Streak
                    </div>
                    <div className="text-white font-medium">
                      {score.currentStreak}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-gray-400 text-sm flex items-center justify-center gap-1">
                      <Trophy size={14} />
                      Best Streak
                    </div>
                    <div className="text-white font-medium">
                      {score.bestStreak}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {scores.length === 0 && (
              <div className="text-center text-gray-400 py-8">
                No scores yet. Be the first to play!
              </div>
            )}
          </div>
        )}
      </div>
    </div>,
    document.body
  )
}

export default Leaderboard
