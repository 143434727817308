import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { X, LogIn, Trophy, Star } from 'lucide-react'
import AuthDialog from './AuthDialog'
import { useStore } from '../store'

interface SignInPromptProps {
  isOpen: boolean
  onClose: () => void
}

export function SignInPrompt({ isOpen, onClose }: SignInPromptProps) {
  const [showAuth, setShowAuth] = useState(false)
  const { setShowSignInPrompt } = useStore()

  const handleSignIn = () => {
    setShowAuth(true)
    setShowSignInPrompt(false)
    onClose()
  }

  const handleAuthClose = () => {
    setShowAuth(false)
  }

  const handleClose = () => {
    onClose()
    setShowSignInPrompt(false)
  }

  if (showAuth) {
    return (
      <AuthDialog 
        isOpen={true} 
        onClose={handleAuthClose}
        initialMode="signup"
      />
    )
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 bg-black/90 backdrop-blur-sm flex items-center justify-center p-4 z-[101]">
        <div className="relative bg-gray-900 p-8 rounded-2xl max-w-md w-full shadow-2xl animate-float-in">
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-white p-2 rounded-full hover:bg-gray-800/50 transition-colors"
          >
            <X size={20} />
          </button>
          
          <div className="space-y-6">
            <div className="flex items-center gap-4">
              <div className="w-12 h-12 rounded-full bg-emerald-500/10 flex items-center justify-center">
                <Trophy className="w-6 h-6 text-emerald-400" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-white">Ready to Join?</h3>
                <p className="text-gray-400">Sign up to continue playing and track your progress!</p>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-3 text-gray-300">
                <Star className="w-5 h-5 text-emerald-400 flex-shrink-0" />
                <span>Track your stats and compete on leaderboards</span>
              </div>
              <div className="flex items-center gap-3 text-gray-300">
                <Star className="w-5 h-5 text-emerald-400 flex-shrink-0" />
                <span>Join tournaments and win prizes</span>
              </div>
              <div className="flex items-center gap-3 text-gray-300">
                <Star className="w-5 h-5 text-emerald-400 flex-shrink-0" />
                <span>Unlock achievements and special features</span>
              </div>
            </div>

            <button
              onClick={handleSignIn}
              className="w-full bg-emerald-600 text-white py-3 px-4 rounded-lg hover:bg-emerald-700 transition-colors flex items-center justify-center gap-2"
            >
              <LogIn size={20} />
              Sign Up Now
            </button>
          </div>
        </div>
      </div>
    </>
  )
} 