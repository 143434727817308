import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { 
  X, Camera, User, Building2, Mail, Phone, 
  Globe2, Bell, Moon, Volume2, VolumeX,
  Loader2, CheckCircle2, AlertCircle, Settings, Plus, Pencil, Trash2, Globe, Users, Save,
  Trophy, GamepadIcon, ShieldCheck, Crown
} from 'lucide-react'
import { auth, db, storage } from '../firebase'
import { doc, updateDoc, getDoc, setDoc, getDocs, collection, serverTimestamp, deleteDoc, writeBatch } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { updateProfile } from 'firebase/auth'
import toast from 'react-hot-toast'
import { UserProfile, UserData, GameVariant, Team } from '../types'
import { getUserData } from '../firebase'
import { gameSounds } from '../utils/sounds'
import { UserManagement } from './admin/UserManagement'
import { TournamentManagement } from './admin/TournamentManagement'

interface SettingsDialogProps {
  isOpen: boolean
  onClose: () => void
}

type Tab = 'profile' | 'preferences' | 'tournaments' | 'game-variants' | 'user-management'

export function SettingsDialog({ isOpen, onClose }: SettingsDialogProps) {
  const [activeTab, setActiveTab] = useState<Tab>('profile')
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [profile, setProfile] = useState<UserProfile>({
    displayName: '',
    photoURL: '',
    department: '',
    email: '',
    phone: '',
    location: '',
    company: ''
  })
  const [preferences, setPreferences] = useState({
    notifications: true,
    darkMode: false,
    soundEffects: true
  })
  const fileInputRef = useRef<HTMLInputElement>(null)
  const user = auth.currentUser
  const [error, setError] = useState<string | null>(null)
  const [isAdmin, setIsAdmin] = useState(false)
  
  // Effect to handle dark mode
  useEffect(() => {
    if (preferences.darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [preferences.darkMode])
  
  const loadUserData = async () => {
    if (!user) return
    
    setIsLoading(true)
    setError(null)
    try {
      console.log('Loading user data for:', user.email)
      const userData = await getUserData(user.uid)
      
      if (userData) {
        console.log('User data loaded:', userData)
        const profileData: UserProfile = {
          displayName: userData.displayName || '',
          photoURL: userData.photoURL || '',
          department: userData.department || '',
          email: userData.email || '',
          phone: userData.phone || '',
          location: userData.location || '',
          company: userData.company || ''
        }
        setProfile(profileData)
        
        if (userData.isAdmin) {
          console.log('Admin user detected from userData')
          setIsAdmin(true)
          await loadAdminData()
        }
        
        const soundEnabled = userData.soundEffects ?? true
        const darkMode = userData.darkMode ?? false
        setPreferences({
          notifications: userData.notifications ?? true,
          darkMode: darkMode,
          soundEffects: soundEnabled
        })
        gameSounds.setEnabled(soundEnabled)
      }
    } catch (error) {
      console.error('Error loading user data:', error)
      setError('Failed to load user data. Please try again.')
      toast.error('Failed to load user data')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      loadUserData()
    }
  }, [isOpen])

  const handleSave = async () => {
    if (!user) return
    
    setIsSaving(true)
    try {
      const userRef = doc(db, 'users', user.uid)
      await updateDoc(userRef, {
        ...profile,
        ...preferences,
        updatedAt: serverTimestamp()
      })
      
      if (profile.displayName) {
        await updateProfile(user, {
          displayName: profile.displayName,
          photoURL: profile.photoURL || null
        })
      }
      
      toast.success('Settings saved successfully')
      onClose()
    } catch (error) {
      console.error('Error saving settings:', error)
      toast.error('Failed to save settings')
    } finally {
      setIsSaving(false)
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file || !user) return
    
    try {
      const storageRef = ref(storage, `profile-photos/${user.uid}`)
      await uploadBytes(storageRef, file)
      const photoURL = await getDownloadURL(storageRef)
      setProfile(prev => ({ ...prev, photoURL }))
    } catch (error) {
      console.error('Error uploading photo:', error)
      toast.error('Failed to upload photo')
    }
  }

  const loadAdminData = async () => {
    // Admin data loading logic here
  }

  if (!isOpen) return null

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-4xl bg-white dark:bg-gray-800 rounded-lg shadow-xl p-6">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X size={24} />
        </button>

        <div className="flex space-x-4 mb-6">
          <button
            onClick={() => setActiveTab('profile')}
            className={`px-4 py-2 rounded-lg ${
              activeTab === 'profile'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
            }`}
          >
            <User className="inline-block mr-2" size={18} />
            Profile
          </button>
          <button
            onClick={() => setActiveTab('preferences')}
            className={`px-4 py-2 rounded-lg ${
              activeTab === 'preferences'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
            }`}
          >
            <Settings className="inline-block mr-2" size={18} />
            Preferences
          </button>
          {isAdmin && (
            <>
              <button
                onClick={() => setActiveTab('tournaments')}
                className={`px-4 py-2 rounded-lg ${
                  activeTab === 'tournaments'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                }`}
              >
                <Trophy className="inline-block mr-2" size={18} />
                Tournaments
              </button>
              <button
                onClick={() => setActiveTab('user-management')}
                className={`px-4 py-2 rounded-lg ${
                  activeTab === 'user-management'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                }`}
              >
                <Users className="inline-block mr-2" size={18} />
                Users
              </button>
            </>
          )}
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <Loader2 className="animate-spin text-blue-500" size={32} />
          </div>
        ) : (
          <div className="space-y-6">
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                <AlertCircle className="inline-block mr-2" size={18} />
                {error}
              </div>
            )}

            {activeTab === 'profile' && (
              <div className="space-y-4">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <img
                      src={profile.photoURL || 'default-avatar.png'}
                      alt="Profile"
                      className="w-24 h-24 rounded-full object-cover"
                    />
                    <button
                      onClick={() => fileInputRef.current?.click()}
                      className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600"
                    >
                      <Camera size={18} />
                    </button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden"
                    />
                  </div>
                  <div className="flex-1 space-y-4">
                    <input
                      type="text"
                      placeholder="Display Name"
                      value={profile.displayName || ''}
                      onChange={e => setProfile(prev => ({ ...prev, displayName: e.target.value }))}
                      className="w-full px-4 py-2 border rounded-lg text-gray-900 dark:text-white bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600"
                    />
                    <input
                      type="text"
                      placeholder="Department"
                      value={profile.department || ''}
                      onChange={e => setProfile(prev => ({ ...prev, department: e.target.value }))}
                      className="w-full px-4 py-2 border rounded-lg text-gray-900 dark:text-white bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600"
                    />
                  </div>
                </div>

                <input
                  type="email"
                  placeholder="Email"
                  value={profile.email || ''}
                  onChange={e => setProfile(prev => ({ ...prev, email: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg text-gray-900 dark:text-white bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600"
                />
                <input
                  type="tel"
                  placeholder="Phone"
                  value={profile.phone || ''}
                  onChange={e => setProfile(prev => ({ ...prev, phone: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg text-gray-900 dark:text-white bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600"
                />
                <input
                  type="text"
                  placeholder="Location"
                  value={profile.location || ''}
                  onChange={e => setProfile(prev => ({ ...prev, location: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg text-gray-900 dark:text-white bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600"
                />
                <input
                  type="text"
                  placeholder="Company"
                  value={profile.company || ''}
                  onChange={e => setProfile(prev => ({ ...prev, company: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg text-gray-900 dark:text-white bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600"
                />
              </div>
            )}

            {activeTab === 'preferences' && (
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Bell size={18} className="text-gray-700 dark:text-gray-300" />
                    <span className="text-gray-900 dark:text-white">Notifications</span>
                  </div>
                  <button
                    onClick={() => setPreferences(prev => ({ ...prev, notifications: !prev.notifications }))}
                    className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                      preferences.notifications ? 'bg-blue-500' : 'bg-gray-200 dark:bg-gray-700'
                    }`}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
                        preferences.notifications ? 'translate-x-6' : 'translate-x-1'
                      }`}
                    />
                  </button>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Moon size={18} className="text-gray-700 dark:text-gray-300" />
                    <span className="text-gray-900 dark:text-white">Dark Mode</span>
                  </div>
                  <button
                    onClick={() => setPreferences(prev => ({ ...prev, darkMode: !prev.darkMode }))}
                    className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                      preferences.darkMode ? 'bg-blue-500' : 'bg-gray-200 dark:bg-gray-700'
                    }`}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
                        preferences.darkMode ? 'translate-x-6' : 'translate-x-1'
                      }`}
                    />
                  </button>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    {preferences.soundEffects ? (
                      <Volume2 size={18} className="text-gray-700 dark:text-gray-300" />
                    ) : (
                      <VolumeX size={18} className="text-gray-700 dark:text-gray-300" />
                    )}
                    <span className="text-gray-900 dark:text-white">Sound Effects</span>
                  </div>
                  <button
                    onClick={() => {
                      setPreferences(prev => ({ ...prev, soundEffects: !prev.soundEffects }))
                      gameSounds.setEnabled(!preferences.soundEffects)
                    }}
                    className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                      preferences.soundEffects ? 'bg-blue-500' : 'bg-gray-200 dark:bg-gray-700'
                    }`}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
                        preferences.soundEffects ? 'translate-x-6' : 'translate-x-1'
                      }`}
                    />
                  </button>
                </div>
              </div>
            )}

            {activeTab === 'tournaments' && isAdmin && (
              <TournamentManagement />
            )}

            {activeTab === 'user-management' && isAdmin && (
              <UserManagement />
            )}

            <div className="flex justify-end space-x-4 mt-6">
              <button
                onClick={onClose}
                className="px-4 py-2 text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                disabled={isSaving}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSaving ? (
                  <>
                    <Loader2 className="inline-block animate-spin mr-2" size={18} />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="inline-block mr-2" size={18} />
                    Save Changes
                  </>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>,
    document.body
  )
}

export default SettingsDialog
