// Tax, Accounting, and CPA Related 5-Letter Words
const TAX_WORDS = [
  // Tax Terms
  'AUDIT', // Tax examination
  'BASIS', // Cost basis
  'BONDS', // Investment type
  'DEBIT', // Accounting entry
  'DEFER', // Postpone taxes
  'DRAFT', // Document prep
  'ENTRY', // Book entry
  'EXCEL', // Essential tool
  'FILED', // Submitted taxes
  'FORMS', // Tax documents
  'FRAUD', // Tax evasion
  'GAINS', // Capital gains
  'GRANT', // Financial aid
  'GROSS', // Total amount
  'HEDGE', // Risk management
  'LEDGE', // Accounting record
  'LEGAL', // Law compliance
  'LIENS', // Tax claims
  'LOANS', // Borrowing
  'MONEY', // Currency
  'OWNER', // Proprietor
  'PAPER', // Documents
  'PRICE', // Cost amount
  'RATIO', // Financial measure
  'SAVED', // Retained earnings
  'SCOPE', // Audit range
  'SHARE', // Stock unit
  'STOCK', // Securities
  'TAXES', // Main theme
  'TOTAL', // Sum amount
  'TRUST', // Legal entity
  'VALUE', // Worth amount
  'WAGES', // Salary paid
  'WORTH', // Net value
  
  // Accounting Specific
  'ASSET', // Resource owned
  'BOOKS', // Financial records
  'COSTS', // Expenses
  'DEBIT', // Left side entry
  'EQUITY', // Ownership value
  'FIXED', // Asset type
  'FUNDS', // Money pools
  'LEASE', // Rental agreement
  'LEVEL', // Account tier
  'MERGE', // Combine entities
  'NOTES', // Disclosures
  'OWING', // Debt owed
  'POSTS', // Entry records
  'QUOTE', // Price estimate
  'RATES', // Interest/Tax rates
  'SALES', // Revenue
  'SPEND', // Expenditure
  'SPLIT', // Division
  'TRIAL', // Balance check
  
  // Finance Related
  'BANKS', // Financial institutions
  'BEARS', // Market pessimists
  'BILLS', // Payables
  'BULLS', // Market optimists
  'BUYER', // Purchaser
  'CARRY', // Tax provision
  'CHAIN', // Supply chain
  'CHART', // Data visual
  'CHECK', // Payment method
  'CLAIM', // Tax/Insurance request
  'CLASS', // Asset category
  'CLEAR', // Transaction complete
  'CLOSE', // Period end
  'COUNT', // Inventory
  'CYCLE', // Business period
  'DAILY', // Frequency
  'DATES', // Time periods
  'DEALS', // Transactions
  'DEPOT', // Storage
  'DRAWN', // Withdrawn
  'EARLY', // Pre-maturity
  'ERROR', // Mistake
  'FLOAT', // Pending funds
  'FLOOR', // Price minimum
  'FLOWS', // Cash movements
  'FOREX', // Foreign exchange
  'GRADE', // Quality level
  'GROUP', // Company structure
  'GUARD', // Protection
  'HAPPY', // Client satisfaction
  'INBOX', // Communications
  'INDEX', // Market indicator
  'INPUT', // Data entry
  'ITEMS', // Line entries
  'JOINT', // Shared account
  'KNOWN', // Identified
  'LABEL', // Classification
  'LATER', // Future date
  'LIMIT', // Maximum amount
  'LOGIC', // Reasoning
  'LOWER', // Reduced amount
  'MACRO', // Big picture
  'MAJOR', // Significant
  'MICRO', // Small scale
  'MINUS', // Subtraction
  'MIXED', // Combined
  'MODEL', // Framework
  'MONTH', // Time period
  'NEEDS', // Requirements
  'NEVER', // Exclusion
  'ORDER', // Sequence
  'PARTY', // Entity
  'PAUSE', // Temporary stop
  'PHASE', // Stage
  'PLACE', // Location
  'PLAIN', // Clear
  'POINT', // Specific item
  'PRIME', // Main
  'PROOF', // Evidence
  'QUEUE', // Sequence
  'QUICK', // Rapid
  'RANGE', // Spread
  'READY', // Prepared
  'REFER', // Reference
  'RIGHT', // Correct
  'ROUND', // Complete
  'ROUTE', // Path
  'SCALE', // Size
  'SCOPE', // Range
  'SCORE', // Rating
  'SETUP', // Configuration
  'SHIFT', // Change
  'SHORT', // Deficit
  'SHOWN', // Displayed
  'SOLID', // Strong
  'SOLVE', // Resolution
  'SPEED', // Velocity
  'STATE', // Condition
  'STORE', // Retain
  'STUDY', // Analysis
  'STYLE', // Method
  'TABLE', // Data display
  'TAKEN', // Acquired
  'TERMS', // Conditions
  'THEME', // Category
  'THIRD', // Additional
  'TIMER', // Duration
  'TIMES', // Periods
  'TITLE', // Ownership
  'TOPIC', // Subject
  'TRACE', // Track
  'TRADE', // Exchange
  'TREND', // Pattern
  'TRIAL', // Test
  'TRUST', // Confidence
  'TRUTH', // Accuracy
  'TYPES', // Categories
  'UNDER', // Below
  'USAGE', // Utilization
  'VALID', // Legitimate
  'VALUE', // Worth
  'VIEWS', // Perspectives
  'VOICE', // Expression
  'WASTE', // Loss
  'WATCH', // Monitor
  'WATER', // Resource
  'WHOLE', // Complete
  'WORTH', // Value
  'YIELD', // Return
] as const;

// Remove duplicates and ensure all words are exactly 5 letters
export const WORDS = [...new Set(TAX_WORDS)];