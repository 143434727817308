import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { X, Crown, Medal, Trophy, User } from 'lucide-react'
import { collection, query, orderBy, limit, getDocs, doc, getDoc } from 'firebase/firestore'
import { db, auth } from '../firebase'
import { Score } from '../types'

interface ScoreboardProps {
  isOpen: boolean
  onClose: () => void
}

export function Scoreboard({ isOpen, onClose }: ScoreboardProps) {
  const [leaderboardScores, setLeaderboardScores] = useState<Score[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (!isOpen) return

    const loadScores = async () => {
      setIsLoading(true)
      setError(null)
      try {
        // Get top scores
        const q = query(
          collection(db, 'scores'),
          orderBy('gamesWon', 'desc'),
          limit(10)
        )
        const snapshot = await getDocs(q)
        const scoreData = await Promise.all(snapshot.docs.map(async docSnapshot => {
          const data = docSnapshot.data()
          // Get user profile data for each score
          const userRef = doc(db, 'users', docSnapshot.id)
          const userDoc = await getDoc(userRef)
          const userData = userDoc.exists() ? userDoc.data() : null
          
          return {
            ...data,
            userId: docSnapshot.id,
            displayName: userData?.displayName || data.displayName || 'Anonymous',
            photoURL: userData?.photoURL || data.photoURL || null,
            lastPlayed: data.lastPlayed?.toDate(),
            createdAt: data.createdAt?.toDate(),
            updatedAt: data.updatedAt?.toDate(),
          }
        })) as Score[]
        
        setLeaderboardScores(scoreData)
      } catch (error) {
        console.error('Error loading scores:', error)
        setError('Failed to load scores')
      } finally {
        setIsLoading(false)
      }
    }

    loadScores()
  }, [isOpen])

  if (!isOpen) return null

  return createPortal(
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm flex items-center justify-center p-4 z-[100]">
      <div className="bg-gray-800 dark:bg-black rounded-lg w-full max-w-2xl overflow-hidden border-2 border-emerald-500 shadow-[0_0_15px_rgba(16,185,129,0.3)]">
        {/* Header */}
        <div className="p-4 border-b border-gray-700 flex items-center justify-between">
          <h2 className="text-xl font-bold text-white flex items-center gap-2">
            <Trophy className="text-emerald-400" />
            Tax Masters Hall of Fame
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold text-white flex items-center gap-2">
                <Crown className="text-yellow-400" />
                Top Players
              </h3>
            </div>
            <div className="space-y-2">
              {leaderboardScores.length > 0 ? (
                leaderboardScores.map((score, index) => (
                  <div 
                    key={score.userId}
                    className="flex items-center gap-4 p-3 bg-gray-700/30 rounded-lg hover:bg-gray-700/40 transition-colors"
                  >
                    <div className="w-8 text-center flex-shrink-0">
                      {index === 0 ? <Crown className="w-6 h-6 text-yellow-400 mx-auto" /> :
                       index === 1 ? <Medal className="w-6 h-6 text-gray-300 mx-auto" /> :
                       index === 2 ? <Medal className="w-6 h-6 text-amber-600 mx-auto" /> :
                       <span className="text-gray-400">#{index + 1}</span>}
                    </div>
                    <div className="flex items-center gap-3 flex-1 min-w-0">
                      {score.photoURL ? (
                        <img 
                          src={score.photoURL} 
                          alt={score.displayName}
                          className="w-8 h-8 rounded-full object-cover border border-gray-700"
                          onError={(e) => {
                            e.currentTarget.style.display = 'none';
                            e.currentTarget.nextElementSibling?.classList.remove('hidden');
                          }}
                        />
                      ) : null}
                      <div className={`w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center ${score.photoURL ? 'hidden' : ''}`}>
                        <User size={16} className="text-gray-400" />
                      </div>
                      <div>
                        <div className="font-medium text-white truncate">{score.displayName}</div>
                        <div className="text-sm text-gray-400">
                          Won: {score.gamesWon} / {score.totalGames} games
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex-shrink-0">
                      <div className="text-emerald-400 font-medium">
                        {((score.gamesWon / (score.totalGames || 1)) * 100).toFixed(1)}%
                      </div>
                      <div className="text-sm text-gray-400">
                        {score.averageGuesses?.toFixed(1) || '0.0'} avg guesses
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center py-4 text-gray-400">
                  No scores yet. Be the first to play!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default Scoreboard