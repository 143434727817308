import React, { useState, useEffect } from 'react'
import { 
  Trophy, Calendar, Clock, Users, Bell, Plus, 
  Pencil, Trash2, Crown, Medal, Gift, Target 
} from 'lucide-react'
import { 
  collection, query, getDocs, doc, setDoc, deleteDoc, 
  serverTimestamp, where, addDoc 
} from 'firebase/firestore'
import { db, auth } from '../../firebase'
import toast from 'react-hot-toast'

interface Tournament {
  id: string
  name: string
  description: string
  startDate: Date
  endDate: Date
  registrationDeadline: Date
  maxTeams: number
  teamSize: number
  prizes: {
    first: string
    second?: string
    third?: string
  }
  rules: string[]
  status: 'upcoming' | 'active' | 'completed'
  gameVariantId: string
  companyOnly: boolean
  notificationsEnabled: boolean
  reminderSchedule: {
    beforeStart: number[] // hours before start
    duringTournament: 'daily' | 'weekly' | 'none'
  }
  teams: {
    id: string
    name: string
    company: string
    members: string[]
    captain: string
    score: number
  }[]
}

interface GameVariant {
  id: string
  name: string
  description: string
  // Add any other necessary properties here
}

export function TournamentManagement() {
  const [tournaments, setTournaments] = useState<Tournament[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [showNewTournamentModal, setShowNewTournamentModal] = useState(false)
  const [selectedTournament, setSelectedTournament] = useState<Tournament | null>(null)
  const [gameVariants, setGameVariants] = useState<GameVariant[]>([])

  useEffect(() => {
    loadTournaments()
    loadGameVariants()
  }, [])

  const loadTournaments = async () => {
    setIsLoading(true)
    try {
      const tournamentsRef = collection(db, 'tournaments')
      const snapshot = await getDocs(tournamentsRef)
      const tournamentsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Tournament[]
      setTournaments(tournamentsData)
    } catch (error) {
      console.error('Error loading tournaments:', error)
      toast.error('Failed to load tournaments')
    } finally {
      setIsLoading(false)
    }
  }

  const loadGameVariants = async () => {
    try {
      const variantsRef = collection(db, 'gameVariants')
      const snapshot = await getDocs(variantsRef)
      const variantsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as GameVariant[]
      setGameVariants(variantsData)
    } catch (error) {
      console.error('Error loading game variants:', error)
    }
  }

  const handleCreateTournament = async (tournamentData: Omit<Tournament, 'id'>) => {
    try {
      const tournamentsRef = collection(db, 'tournaments')
      const docRef = await addDoc(tournamentsRef, {
        ...tournamentData,
        createdBy: auth.currentUser?.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      })

      // Create announcement
      await addDoc(collection(db, 'announcements'), {
        type: 'tournament',
        tournamentId: docRef.id,
        title: `New Tournament: ${tournamentData.name}`,
        message: `Registration is now open for ${tournamentData.name}! Join now to compete for amazing prizes.`,
        startDate: tournamentData.startDate,
        endDate: tournamentData.endDate,
        createdAt: serverTimestamp()
      })

      toast.success('Tournament created successfully')
      loadTournaments()
    } catch (error) {
      console.error('Error creating tournament:', error)
      toast.error('Failed to create tournament')
    }
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold text-white flex items-center gap-2">
          <Trophy className="text-emerald-400" />
          Tournaments
        </h3>
        <button
          onClick={() => setShowNewTournamentModal(true)}
          className="bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-emerald-700 transition-colors flex items-center gap-2"
        >
          <Plus size={18} />
          New Tournament
        </button>
      </div>

      {/* Tournament List */}
      <div className="space-y-4">
        {tournaments.map((tournament) => (
          <div
            key={tournament.id}
            className="bg-gray-700/30 rounded-lg p-4"
          >
            <div className="flex items-center justify-between mb-2">
              <h4 className="font-medium text-white">{tournament.name}</h4>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => {
                    setSelectedTournament(tournament)
                    setShowNewTournamentModal(true)
                  }}
                  className="p-2 text-gray-400 hover:text-white rounded-full hover:bg-gray-700 transition-colors"
                >
                  <Pencil size={18} />
                </button>
                <button
                  onClick={async () => {
                    if (confirm('Are you sure you want to delete this tournament?')) {
                      try {
                        await deleteDoc(doc(db, 'tournaments', tournament.id))
                        toast.success('Tournament deleted')
                        loadTournaments()
                      } catch (error) {
                        console.error('Error deleting tournament:', error)
                        toast.error('Failed to delete tournament')
                      }
                    }
                  }}
                  className="p-2 text-red-400 hover:text-red-300 rounded-full hover:bg-gray-700 transition-colors"
                >
                  <Trash2 size={18} />
                </button>
              </div>
            </div>
            
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-sm">
              <div className="flex items-center gap-2 text-gray-400">
                <Calendar size={16} />
                {new Date(tournament.startDate).toLocaleDateString()}
              </div>
              <div className="flex items-center gap-2 text-gray-400">
                <Users size={16} />
                {tournament.teams.length} / {tournament.maxTeams} Teams
              </div>
              <div className="flex items-center gap-2 text-gray-400">
                <Crown size={16} />
                {tournament.prizes.first}
              </div>
              <div className="flex items-center gap-2 text-gray-400">
                <Target size={16} />
                {tournament.status}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* New Tournament Modal */}
      {/* We'll implement this next */}
    </div>
  )
} 