import { create } from 'zustand';
import { GameState, GameVariant } from './types';
import { WORDS } from './words';
import { auth, db } from './firebase';
import { doc, updateDoc, increment, getDoc, setDoc, collection, query, where, getDocs, addDoc, serverTimestamp, limit } from 'firebase/firestore';
import toast from 'react-hot-toast';

const getRandomWord = () => WORDS[Math.floor(Math.random() * WORDS.length)];

interface ScoreData {
  userId: string
  displayName: string
  photoURL?: string | null
  totalGames: number
  gamesWon: number
  gamesLost: number
  averageGuesses: number
  lastPlayed: Date
  currentStreak: number
  bestStreak: number
  createdAt: Date
  updatedAt: Date
  variantId?: string
}

async function updateUserScore({ 
  userId, 
  displayName, 
  isWin, 
  guessCount,
  variantId 
}: {
  userId: string
  displayName: string
  isWin: boolean
  guessCount: number
  variantId?: string | null
}) {
  const scoreRef = doc(db, 'scores', userId);
  
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Not signed in');
    if (user.uid !== userId) throw new Error('Unauthorized to update score');

    const now = new Date();
    
    // First check if score document exists
    const scoreDoc = await getDoc(scoreRef);
    
    if (!scoreDoc.exists()) {
      // Create initial score document - omit variantId if not provided
      const initialScore: Partial<ScoreData> = {
        userId,
        displayName,
        photoURL: user.photoURL,
        totalGames: 1,
        gamesWon: isWin ? 1 : 0,
        gamesLost: isWin ? 0 : 1,
        averageGuesses: guessCount,
        lastPlayed: now,
        currentStreak: isWin ? 1 : 0,
        bestStreak: isWin ? 1 : 0,
        createdAt: now,
        updatedAt: now
      };

      // Only add variantId if it exists
      if (variantId) {
        initialScore.variantId = variantId;
      }

      await setDoc(scoreRef, initialScore);
      
      if (isWin) {
        toast.success('First win recorded! 🎯');
      }
    } else {
      // Update existing score
      const currentData = scoreDoc.data();
      const totalGames = (currentData.totalGames || 0) + 1;
      const gamesWon = (currentData.gamesWon || 0) + (isWin ? 1 : 0);
      const currentAvg = currentData.averageGuesses || 0;
      const newStreak = isWin ? (currentData.currentStreak || 0) + 1 : 0;
      
      const updateData: Partial<ScoreData> = {
        totalGames,
        gamesWon,
        gamesLost: totalGames - gamesWon,
        averageGuesses: ((currentAvg * (totalGames - 1)) + guessCount) / totalGames,
        lastPlayed: now,
        currentStreak: newStreak,
        bestStreak: Math.max(newStreak, currentData.bestStreak || 0),
        updatedAt: now
      };

      // Only update variantId if it exists
      if (variantId) {
        updateData.variantId = variantId;
      }

      await updateDoc(scoreRef, updateData);
      
      if (isWin) {
        toast.success('Stats updated! 🎯');
      }
    }
  } catch (error: any) {
    console.error('Error updating score:', error);
    toast.error(error.message || 'Failed to update stats');
  }
}

const getActiveGameVariant = async (): Promise<GameVariant | null> => {
  try {
    const now = new Date();
    const q = query(
      collection(db, 'gameVariants'),
      where('active', '==', true),
      where('startDate', '<=', now),
      where('endDate', '>=', now)
    );
    
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
      const variant = snapshot.docs[0].data() as GameVariant;
      return { ...variant, id: snapshot.docs[0].id };
    }
  } catch (error) {
    console.error('Error fetching game variant:', error);
  }
  return null;
};

export const useStore = create<GameState>((set, get) => ({
  guesses: [],
  currentGuess: '',
  gameStatus: 'playing',
  solution: getRandomWord(),
  currentVariant: null,
  guestPlays: 0,
  showSignInPrompt: false,
  weeklyWord: null,
  weeklyVariant: null,
  
  initializeGame: async () => {
    try {
      await get().checkWeeklyGame();
      
      if (!get().weeklyWord) {
        const variant = await getActiveGameVariant();
        if (variant?.wordList?.length) {
          set({ 
            currentVariant: variant,
            solution: getRandomWord()
          });
        } else {
          set({ 
            currentVariant: null,
            solution: getRandomWord()
          });
        }
      }
    } catch (error) {
      console.error('Error initializing game:', error);
      set(state => ({
        solution: state.solution || getRandomWord()
      }));
    }
  },

  addGuess: async (guess: string) => {
    const state = get();
    if (!state.solution) return;

    const newGuesses = [...state.guesses, guess];
    let gameStatus = state.gameStatus;

    if (guess.toUpperCase() === state.solution.toUpperCase()) {
      gameStatus = 'won';
    } else if (newGuesses.length >= 6) {
      gameStatus = 'lost';
    }

    // Update stats when game is finished
    if (gameStatus !== 'playing' && auth.currentUser) {
      try {
        // First ensure user document exists
        const userRef = doc(db, 'users', auth.currentUser.uid);
        await setDoc(userRef, {
          email: auth.currentUser.email,
          displayName: auth.currentUser.displayName || auth.currentUser.email?.split('@')[0] || 'Anonymous',
          updatedAt: serverTimestamp()
        }, { merge: true });

        // Then update score
        await updateUserScore({
          userId: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || auth.currentUser.email?.split('@')[0] || 'Anonymous',
          isWin: gameStatus === 'won',
          guessCount: newGuesses.length,
          variantId: state.currentVariant?.id
        });
      } catch (error) {
        console.error('Error updating score:', error);
        toast.error('Failed to update stats. Please try again.');
      }
    }
    
    set({ 
      guesses: newGuesses, 
      currentGuess: '',
      gameStatus 
    });
  },

  addLetter: (letter: string) => {
    set((state) => {
      if (state.currentGuess.length === 5) return state;
      return { currentGuess: state.currentGuess + letter };
    });
  },

  removeLetter: () => {
    set((state) => ({
      currentGuess: state.currentGuess.slice(0, -1),
    }));
  },

  resetGame: async () => {
    const state = get()
    const isGuest = !auth.currentUser

    if (isGuest) {
      const newGuestPlays = state.guestPlays + 1
      set({ guestPlays: newGuestPlays })

      if (newGuestPlays >= 2) {
        set({ showSignInPrompt: true })
        return
      }
    }

    const newSolution = getRandomWord()
    set({
      guesses: [],
      currentGuess: '',
      gameStatus: 'playing',
      solution: newSolution
    })

    try {
      await get().initializeGame()
    } catch (error) {
      console.error('Error resetting game:', error)
    }
  },

  setShowSignInPrompt: (show: boolean) => set({ showSignInPrompt: show }),

  checkWeeklyGame: async () => {
    try {
      const now = new Date()
      const startOfWeek = new Date(now)
      startOfWeek.setHours(0, 0, 0, 0)
      startOfWeek.setDate(now.getDate() - now.getDay()) // Start of current week

      const q = query(
        collection(db, 'weeklyGames'),
        where('startDate', '<=', now),
        where('endDate', '>=', now),
        limit(1)
      )

      const snapshot = await getDocs(q)
      if (!snapshot.empty) {
        const weeklyGame = snapshot.docs[0].data()
        set({ 
          weeklyWord: weeklyGame.word,
          weeklyVariant: weeklyGame.variant,
          solution: weeklyGame.word,
          currentVariant: weeklyGame.variant
        })

        // Show notification for new weekly game
        const lastPlayed = localStorage.getItem('lastWeeklyGame')
        if (lastPlayed !== weeklyGame.startDate.toISOString()) {
          toast.success('New weekly challenge available! 🎮', {
            duration: 5000,
            icon: '🎯'
          })
          localStorage.setItem('lastWeeklyGame', weeklyGame.startDate.toISOString())
        }
      } else {
        set({ weeklyWord: null, weeklyVariant: null })
      }
    } catch (error) {
      console.error('Error checking weekly game:', error)
      set({ weeklyWord: null, weeklyVariant: null })
    }
  },
}));

export async function createWeeklyGame(word: string, variant: GameVariant) {
  const now = new Date()
  const startOfWeek = new Date(now)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(now.getDate() - now.getDay())

  const endOfWeek = new Date(startOfWeek)
  endOfWeek.setDate(startOfWeek.getDate() + 6)
  endOfWeek.setHours(23, 59, 59, 999)

  await addDoc(collection(db, 'weeklyGames'), {
    word,
    variant,
    startDate: startOfWeek,
    endDate: endOfWeek,
    createdAt: serverTimestamp()
  })
}