import React, { useEffect } from 'react';
import { useStore } from '../store';
import { gameSounds } from '../utils/sounds';

const Cell: React.FC<{
  letter: string;
  status: 'correct' | 'present' | 'absent' | 'empty' | 'current';
  index: number;
}> = ({ letter, status, index }) => {
  const baseClasses = "w-full aspect-square border-2 flex items-center justify-center text-2xl font-bold rounded transition-all duration-300";
  
  const statusClasses = {
    correct: "bg-emerald-500 text-white border-emerald-500 animate-celebrate animate-shimmer",
    present: "bg-amber-500 text-white border-amber-500 animate-pop",
    absent: "bg-gray-600 text-white border-gray-600 animate-wiggle",
    empty: "border-gray-700 text-white",
    current: "border-gray-500 text-white animate-float-in"
  };

  useEffect(() => {
    if (status === 'correct') {
      gameSounds.play('correct')
    } else if (status === 'present') {
      gameSounds.play('pop')
    } else if (status === 'absent') {
      gameSounds.play('wrong')
    }
  }, [status])

  const animationDelay = status !== 'empty' && status !== 'current' 
    ? { style: { animationDelay: `${index * 150}ms` } }
    : {};

  return (
    <div 
      className={`${baseClasses} ${statusClasses[status]}`}
      {...animationDelay}
    >
      {letter}
    </div>
  );
};

const Grid: React.FC = () => {
  const { guesses, currentGuess, solution } = useStore();

  const getStatus = (letter: string, index: number) => {
    if (letter === solution[index]) return 'correct';
    if (solution.includes(letter)) return 'present';
    return 'absent';
  };

  return (
    <div className="grid grid-rows-6 gap-1.5">
      {[...Array(6)].map((_, rowIndex) => (
        <div key={rowIndex} className="grid grid-cols-5 gap-1.5">
          {[...Array(5)].map((_, colIndex) => {
            const guess = guesses[rowIndex];
            const isCurrentGuess = rowIndex === guesses.length;
            const letter = isCurrentGuess 
              ? currentGuess[colIndex] || ''
              : guess?.[colIndex] || '';
            
            let status: 'correct' | 'present' | 'absent' | 'empty' | 'current' = 'empty';
            
            if (guess) {
              status = getStatus(letter, colIndex);
            } else if (isCurrentGuess && letter) {
              status = 'current';
            }

            return (
              <Cell 
                key={colIndex} 
                letter={letter} 
                status={status} 
                index={colIndex}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default Grid;