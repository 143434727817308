import { auth, db } from '../firebase'
import { doc, getDoc } from 'firebase/firestore'

class GameSounds {
  private static instance: GameSounds
  private soundEnabled: boolean = true
  private sounds: { [key: string]: HTMLAudioElement } = {}
  private initialized: boolean = false
  private soundsLoaded: boolean = false
  
  private constructor() {
    // Pre-load all sounds
    this.sounds = {
      pop: new Audio('/sounds/pop.mp3'),
      correct: new Audio('/sounds/correct.mp3'),
      wrong: new Audio('/sounds/wrong.mp3'),
      victory: new Audio('/sounds/victory.mp3'),
      flip: new Audio('/sounds/flip.mp3'),
      type: new Audio('/sounds/type.mp3'),
      error: new Audio('/sounds/error.mp3'),
      achievement: new Audio('/sounds/achievement.mp3'),
      click: new Audio('/sounds/click.mp3')
    }
    
    // Set click sound to 20% volume
    this.sounds.click.volume = 0.2
    
    // Load user preference from localStorage as fallback
    const savedPreference = localStorage.getItem('soundEnabled')
    if (savedPreference !== null) {
      this.soundEnabled = savedPreference === 'true'
    }

    // Check if sounds are loaded
    Promise.all(
      Object.values(this.sounds).map(sound => 
        new Promise(resolve => {
          sound.addEventListener('canplaythrough', resolve, { once: true })
          sound.addEventListener('error', resolve, { once: true })
          sound.load()
          sound.volume = 0.5
        })
      )
    ).then(() => {
      this.soundsLoaded = true
    }).catch(error => {
      console.error('Error loading sounds:', error)
      this.soundsLoaded = false
    })
  }

  static getInstance(): GameSounds {
    if (!GameSounds.instance) {
      GameSounds.instance = new GameSounds()
    }
    return GameSounds.instance
  }

  async initializeWithUserPreference() {
    if (this.initialized) return

    try {
      const user = auth.currentUser
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid))
        const userData = userDoc.data()
        if (userData) {
          this.soundEnabled = userData.soundEffects ?? true
          localStorage.setItem('soundEnabled', String(this.soundEnabled))
        }
      }
    } catch (error) {
      console.error('Error loading sound preference:', error)
    } finally {
      this.initialized = true
    }
  }

  setEnabled(enabled: boolean) {
    this.soundEnabled = enabled
    localStorage.setItem('soundEnabled', String(enabled))
  }

  play(soundName: keyof typeof this.sounds) {
    if (!this.soundEnabled || !this.soundsLoaded) return
    
    const sound = this.sounds[soundName]
    if (sound) {
      sound.currentTime = 0
      sound.play().catch(error => {
        console.warn(`Failed to play sound ${soundName}:`, error)
      })
    }
  }

  isEnabled(): boolean {
    return this.soundEnabled
  }

  isSoundsLoaded(): boolean {
    return this.soundsLoaded
  }
}

export const gameSounds = GameSounds.getInstance() 