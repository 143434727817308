import React from 'react'
import { createPortal } from 'react-dom'
import { X, HelpCircle, AlertCircle } from 'lucide-react'

interface RulesProps {
  isOpen: boolean
  onClose: () => void
}

export function Rules({ isOpen, onClose }: RulesProps) {
  if (!isOpen) return null

  return createPortal(
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm flex items-center justify-center p-4 z-[100]">
      <div className="bg-gray-800 rounded-lg p-8 max-w-2xl w-full border-2 border-emerald-500 shadow-[0_0_15px_rgba(16,185,129,0.3)] animate-fade-in overflow-y-auto max-h-[90vh]">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white flex items-center gap-2">
            <HelpCircle className="text-emerald-500" />
            How to Play Taxle
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        <div className="space-y-6 text-gray-300">
          <section className="space-y-3">
            <h3 className="text-lg font-semibold text-emerald-400">Game Objective</h3>
            <p>
              Guess the tax-related term in 6 tries or less. Each guess must be a valid 5-letter word related to taxation, accounting, or finance.
            </p>
          </section>

          <section className="space-y-3">
            <h3 className="text-lg font-semibold text-emerald-400">How It Works</h3>
            <div className="space-y-2">
              <p>After each guess, the color of the tiles will change to show how close your guess was:</p>
              <ul className="space-y-3 mt-4">
                <li className="flex items-center gap-3">
                  <div className="w-10 h-10 bg-emerald-500 rounded flex items-center justify-center text-white font-bold">
                    A
                  </div>
                  <span>Green: Letter is correct and in the right spot</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-10 h-10 bg-yellow-500 rounded flex items-center justify-center text-white font-bold">
                    B
                  </div>
                  <span>Yellow: Letter is in the word but in the wrong spot</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-10 h-10 bg-gray-600 rounded flex items-center justify-center text-white font-bold">
                    C
                  </div>
                  <span>Gray: Letter is not in the word</span>
                </li>
              </ul>
            </div>
          </section>

          <section className="space-y-3">
            <h3 className="text-lg font-semibold text-emerald-400">Example</h3>
            <div className="grid grid-cols-5 gap-1 max-w-xs">
              <div className="w-full h-12 bg-emerald-500 rounded flex items-center justify-center text-white font-bold">T</div>
              <div className="w-full h-12 bg-gray-600 rounded flex items-center justify-center text-white font-bold">A</div>
              <div className="w-full h-12 bg-yellow-500 rounded flex items-center justify-center text-white font-bold">X</div>
              <div className="w-full h-12 bg-gray-600 rounded flex items-center justify-center text-white font-bold">E</div>
              <div className="w-full h-12 bg-gray-600 rounded flex items-center justify-center text-white font-bold">S</div>
            </div>
            <p className="text-sm">
              In this example, "T" is in the correct spot, "X" is in the word but in the wrong position, and the other letters are not in the word.
            </p>
          </section>

          <div className="mt-8 p-4 bg-gray-700 rounded-lg flex items-start gap-3">
            <AlertCircle className="text-emerald-400 flex-shrink-0 mt-1" />
            <div className="space-y-2">
              <p className="font-medium text-emerald-400">Pro Tips:</p>
              <ul className="list-disc list-inside space-y-1 text-sm">
                <li>Start with words that have common tax-related letters</li>
                <li>Pay attention to letters that appear in yellow - they're key to solving the puzzle</li>
                <li>Use the on-screen keyboard to track which letters you've tried</li>
                <li>Create an account to track your progress and compete on the leaderboard</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
} 