import React from 'react'
import { createPortal } from 'react-dom'
import { X, Shield } from 'lucide-react'

interface PrivacyPolicyProps {
  isOpen: boolean
  onClose: () => void
}

export function PrivacyPolicy({ isOpen, onClose }: PrivacyPolicyProps) {
  if (!isOpen) return null

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center p-4 z-[100]">
      <div className="fixed inset-0 bg-black/90" onClick={onClose} />
      <div className="relative bg-gray-900 w-full max-w-3xl rounded-lg overflow-hidden shadow-2xl border-2 border-emerald-500 animate-content-show">
        {/* Header */}
        <div className="bg-gradient-to-r from-gray-900 via-emerald-900 to-gray-900 p-6 border-b border-emerald-500/20">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold text-white flex items-center gap-3">
              <Shield className="text-emerald-400" />
              <span className="bg-gradient-to-r from-white via-emerald-300 to-white text-transparent bg-clip-text">
                Privacy Policy
              </span>
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white p-2 hover:bg-gray-800 rounded-full transition-colors"
            >
              <X size={24} />
            </button>
          </div>
          <p className="text-gray-400 mt-2">Last Updated: November 24, 2024</p>
        </div>

        {/* Content */}
        <div className="p-6 overflow-y-auto max-h-[70vh] space-y-6 text-gray-300">
          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">1. Information We Collect</h3>
            <p>
              We collect information that you provide directly to us, including:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Account information (email, display name)</li>
              <li>Profile information (photo, department, location)</li>
              <li>Game statistics and performance data</li>
              <li>Communications with us</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">2. How We Use Information</h3>
            <p>
              We use the collected information to:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Provide and improve Taxle</li>
              <li>Track game progress and statistics</li>
              <li>Communicate with you about updates</li>
              <li>Maintain leaderboards and achievements</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">3. Information Sharing</h3>
            <p>
              We do not sell your personal information. Your game statistics and profile information may be visible to other users through leaderboards and public profiles.
            </p>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">4. Data Security</h3>
            <p>
              We implement reasonable security measures to protect your information. However, no method of transmission over the Internet is 100% secure.
            </p>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">5. Your Rights</h3>
            <p>
              You can:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Access your personal information</li>
              <li>Update or correct your information</li>
              <li>Request deletion of your account</li>
              <li>Opt-out of communications</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">6. Changes to Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
            </p>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">7. Contact Us</h3>
            <p>
              For any questions about this Privacy Policy, please contact us at taxleapp@gmail.com
            </p>
          </section>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default PrivacyPolicy 