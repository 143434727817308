import React from 'react'
import { createPortal } from 'react-dom'
import { X, Scale } from 'lucide-react'

interface TermsOfServiceProps {
  isOpen: boolean
  onClose: () => void
}

export function TermsOfService({ isOpen, onClose }: TermsOfServiceProps) {
  if (!isOpen) return null

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center p-4 z-[100]">
      <div className="fixed inset-0 bg-black/90" onClick={onClose} />
      <div className="relative bg-gray-900 w-full max-w-3xl rounded-lg overflow-hidden shadow-2xl border-2 border-emerald-500 animate-content-show">
        <div className="bg-gradient-to-r from-gray-900 via-emerald-900 to-gray-900 p-6 border-b border-emerald-500/20">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold text-white flex items-center gap-3">
              <Scale className="text-emerald-400" />
              <span className="bg-gradient-to-r from-white via-emerald-300 to-white text-transparent bg-clip-text">
                Terms of Service
              </span>
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white p-2 hover:bg-gray-800 rounded-full transition-colors"
            >
              <X size={24} />
            </button>
          </div>
          <p className="text-gray-400 mt-2">Last Updated: November 24, 2024</p>
        </div>

        <div className="p-6 overflow-y-auto max-h-[70vh] space-y-6 text-gray-300">
          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">1. Terms</h3>
            <p>
              By accessing Taxle, you agree to be bound by these Terms of Service. The game is currently provided free of charge, but we reserve the right to modify, suspend, or charge for the service in the future.
            </p>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">2. Use License</h3>
            <p>
              Permission is granted to temporarily access Taxle for personal, non-commercial use. This license does not include:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Modifying or copying game materials</li>
              <li>Using materials for commercial purposes</li>
              <li>Attempting to reverse engineer the game</li>
              <li>Removing any copyright or proprietary notations</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">3. Disclaimer</h3>
            <p>
              Taxle materials are provided "as is". We make no warranties, expressed or implied, and hereby disclaim all warranties, including accuracy of tax-related content.
            </p>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">4. Limitations</h3>
            <p>
              We shall not be held liable for any damages arising from the use or inability to use Taxle. Some jurisdictions do not allow limitations on implied warranties or limitations of liability, so these limitations may not apply to you.
            </p>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">5. Revisions</h3>
            <p>
              We reserve the right to revise these Terms of Service at any time without notice. By using Taxle, you agree to be bound by the current version of these Terms of Service.
            </p>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">6. Governing Law</h3>
            <p>
              These terms shall be governed by and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.
            </p>
          </section>

          <section className="space-y-4">
            <h3 className="text-lg font-semibold text-white">7. Contact</h3>
            <p>
              For any questions about these Terms of Service, please contact us at taxleapp@gmail.com
            </p>
          </section>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default TermsOfService 