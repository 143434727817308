import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { RefreshCw } from 'lucide-react';
import { useStore } from '../store';
import { gameSounds } from '../utils/sounds';
import { auth } from '../firebase';
import { SignInPrompt } from './SignInPrompt';

function GameOver() {
  const { gameStatus, solution, resetGame, guesses, showSignInPrompt, setShowSignInPrompt, guestPlays } = useStore();
  
  useEffect(() => {
    if (gameStatus === 'won') {
      gameSounds.play('victory')
      gameSounds.play('achievement')
    } else if (gameStatus === 'lost') {
      gameSounds.play('wrong')
    }

    // Show sign in prompt for guest users after second game
    if (gameStatus !== 'playing' && !auth.currentUser && guestPlays === 2) {
      setShowSignInPrompt(true)
    }
  }, [gameStatus, setShowSignInPrompt, guestPlays])

  if (gameStatus === 'playing') return null;

  const messages = {
    won: [
      '🎉 Tax Expert!',
      '💫 Outstanding!',
      '🌟 Tax Whiz!',
      '✨ Brilliant!'
    ],
    lost: [
      '📋 Better Luck Next Time!',
      '💼 Keep Learning!',
      '📊 Try Again!'
    ]
  };

  const getMessage = () => {
    const list = gameStatus === 'won' ? messages.won : messages.lost;
    return list[Math.floor(Math.random() * list.length)];
  };

  const handlePlayAgain = async () => {
    try {
      // For guest users after their second game, show sign in prompt
      if (!auth.currentUser && guestPlays >= 2) {
        setShowSignInPrompt(true)
        return
      }
      await resetGame();
    } catch (error) {
      console.error('Error resetting game:', error);
    }
  };

  return createPortal(
    <>
      <div className="fixed inset-0 bg-black/90 dark:bg-black/95 backdrop-blur-sm flex items-center justify-center p-4 z-[100]">
        <div className={`bg-gray-800 dark:bg-black rounded-lg p-8 max-w-sm w-full border-2 border-emerald-500 
          shadow-[0_0_15px_rgba(16,185,129,0.3)] transition-colors duration-200
          ${gameStatus === 'won' ? 'animate-celebrate' : 'animate-float-in'}`}>
          <h2 className="text-3xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-emerald-400 to-emerald-600">
            {getMessage()}
          </h2>
          <p className="text-center mb-4 text-gray-300">
            The tax term was: <span className="font-bold text-emerald-400">{solution}</span>
          </p>
          {gameStatus === 'won' ? (
            <p className="text-center mb-8 text-gray-300">
              You got it in {guesses.length} {guesses.length === 1 ? 'try' : 'tries'}
            </p>
          ) : (
            <p className="text-center mb-8 text-gray-300">
              Better luck next time!
            </p>
          )}
          <button
            onClick={handlePlayAgain}
            className="w-full bg-emerald-600 text-white py-3 px-4 rounded-lg hover:bg-emerald-700 transition-colors flex items-center justify-center gap-2 group"
          >
            <RefreshCw size={20} className="group-hover:animate-spin" />
            Play Again
          </button>
          
          {gameStatus === 'won' && (
            <div className="absolute -inset-4 animate-shimmer opacity-20 pointer-events-none" />
          )}
        </div>
      </div>

      <SignInPrompt 
        isOpen={showSignInPrompt} 
        onClose={() => setShowSignInPrompt(false)} 
      />
    </>,
    document.body
  );
}

export default GameOver;