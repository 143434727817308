import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { X, Trophy, Target, Calendar, Award, BarChart2, Clock, Sparkles } from 'lucide-react'
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore'
import { db, auth } from '../firebase'
import { Score } from '../types'
import { toast } from 'react-hot-toast'

interface StatsDialogProps {
  isOpen: boolean
  onClose: () => void
}

export function StatsDialog({ isOpen, onClose }: StatsDialogProps) {
  const [stats, setStats] = useState<Score | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [timeFrame, setTimeFrame] = useState<'all' | 'month' | 'week'>('all')
  const user = auth.currentUser
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (isOpen && user) {
      loadStats()
    }
  }, [isOpen, timeFrame, user])

  const loadStats = async () => {
    if (!user) return;

    setIsLoading(true);
    setError(null);
    try {
      const scoreRef = collection(db, 'scores');
      let scoreQuery = query(
        scoreRef,
        where('userId', '==', user.uid)
      );

      const snapshot = await getDocs(scoreQuery);
      if (!snapshot.empty) {
        const sortedDocs = snapshot.docs.sort((a, b) => {
          const aDate = a.data().lastPlayed?.toDate() || new Date(0);
          const bDate = b.data().lastPlayed?.toDate() || new Date(0);
          return bDate.getTime() - aDate.getTime();
        });
        const userData = sortedDocs[0].data() as Score;
        setStats(userData);
      }
    } catch (error) {
      console.error('Error loading stats:', error);
      toast.error('Unable to load stats. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null

  const winRate = stats ? ((stats.gamesWon / stats.totalGames) * 100).toFixed(1) : '0'

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center p-4 z-[100]">
      <div className="fixed inset-0 bg-black/90" onClick={onClose} />
      <div className="relative bg-gray-900 w-full max-w-2xl rounded-lg overflow-hidden shadow-2xl border-2 border-emerald-500 animate-content-show">
        {/* Header */}
        <div className="bg-gradient-to-r from-gray-900 via-emerald-900 to-gray-900 p-6 border-b border-emerald-500/20">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold text-white flex items-center gap-3">
              <BarChart2 className="text-emerald-400" />
              <span className="bg-gradient-to-r from-white via-emerald-300 to-white text-transparent bg-clip-text">
                Your Stats
              </span>
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white p-2 hover:bg-gray-800 rounded-full transition-colors"
            >
              <X size={24} />
            </button>
          </div>
        </div>

        {/* Time Frame Selector */}
        <div className="flex border-b border-gray-800/50">
          <button
            onClick={() => setTimeFrame('all')}
            className={`flex-1 py-2.5 text-sm font-medium transition-colors ${
              timeFrame === 'all' 
                ? 'text-emerald-400 border-b-2 border-emerald-400 bg-emerald-400/5' 
                : 'text-gray-400 hover:text-white hover:bg-gray-800/30'
            }`}
          >
            All Time
          </button>
          <button
            onClick={() => setTimeFrame('month')}
            className={`flex-1 py-2.5 text-sm font-medium transition-colors ${
              timeFrame === 'month'
                ? 'text-emerald-400 border-b-2 border-emerald-400 bg-emerald-400/5'
                : 'text-gray-400 hover:text-white hover:bg-gray-800/30'
            }`}
          >
            This Month
          </button>
          <button
            onClick={() => setTimeFrame('week')}
            className={`flex-1 py-2.5 text-sm font-medium transition-colors ${
              timeFrame === 'week'
                ? 'text-emerald-400 border-b-2 border-emerald-400 bg-emerald-400/5'
                : 'text-gray-400 hover:text-white hover:bg-gray-800/30'
            }`}
          >
            This Week
          </button>
        </div>

        {/* Stats Grid */}
        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500" />
          </div>
        ) : stats ? (
          <div className="p-6">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {/* Games Played */}
              <div className="bg-gray-800/50 rounded-lg p-4 text-center">
                <Target className="w-6 h-6 text-emerald-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-white">{stats.totalGames}</div>
                <div className="text-sm text-gray-400">Games Played</div>
              </div>

              {/* Win Rate */}
              <div className="bg-gray-800/50 rounded-lg p-4 text-center">
                <Trophy className="w-6 h-6 text-emerald-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-white">{winRate}%</div>
                <div className="text-sm text-gray-400">Win Rate</div>
              </div>

              {/* Current Streak */}
              <div className="bg-gray-800/50 rounded-lg p-4 text-center">
                <Sparkles className="w-6 h-6 text-emerald-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-white">{stats.currentStreak || 0}</div>
                <div className="text-sm text-gray-400">Current Streak</div>
              </div>

              {/* Best Streak */}
              <div className="bg-gray-800/50 rounded-lg p-4 text-center">
                <Award className="w-6 h-6 text-emerald-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-white">{stats.bestStreak || 0}</div>
                <div className="text-sm text-gray-400">Best Streak</div>
              </div>
            </div>

            {/* Additional Stats */}
            <div className="mt-6 space-y-4">
              <div className="bg-gray-800/30 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Clock className="w-5 h-5 text-emerald-400" />
                    <span className="text-gray-300">Average Guesses</span>
                  </div>
                  <span className="text-lg font-medium text-white">
                    {stats.averageGuesses.toFixed(1)}
                  </span>
                </div>
              </div>

              <div className="bg-gray-800/30 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Calendar className="w-5 h-5 text-emerald-400" />
                    <span className="text-gray-300">Last Played</span>
                  </div>
                  <span className="text-lg font-medium text-white">
                    {new Date(stats.lastPlayed).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-64">
            <Trophy className="w-12 h-12 text-gray-600 mb-4" />
            <p className="text-gray-400">No stats available yet. Start playing to see your progress!</p>
          </div>
        )}
      </div>
    </div>,
    document.body
  )
}

export default StatsDialog 