import React from 'react';
import { useStore } from '../store';
import type { KeyboardKey } from '../types';
import { gameSounds } from '../utils/sounds';

const KEYBOARD_ROWS = [
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
  ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
  ['ENTER', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '⌫'],
];

const Keyboard: React.FC = () => {
  const { addLetter, removeLetter, addGuess, currentGuess, guesses, solution } = useStore();

  const getKeyStatus = (key: string): KeyboardKey['status'] => {
    const guessedLetters = guesses.join('');
    if (!guessedLetters.includes(key)) return 'unused';
    if (solution.includes(key)) {
      if ([...guesses.join('')].some((letter, i) => letter === key && solution[i % 5] === key)) {
        return 'correct';
      }
      return 'present';
    }
    return 'absent';
  };

  const handleKeyClick = (key: string) => {
    if (gameSounds.isEnabled()) {
      gameSounds.play('click')
    }
    
    if (key === 'ENTER' && currentGuess.length === 5) {
      gameSounds.play('flip')
      addGuess(currentGuess)
    } else if (key === '⌫') {
      gameSounds.play('pop')
      removeLetter()
    } else if (key.length === 1 && currentGuess.length < 5) {
      gameSounds.play('type')
      addLetter(key)
    } else if (currentGuess.length >= 5) {
      gameSounds.play('error')
    }
  };

  const getKeyClasses = (status: KeyboardKey['status']) => {
    const base = "rounded font-bold transition-all duration-150 text-sm sm:text-base active:scale-95 touch-manipulation";
    const sizes = "h-12 sm:h-14 min-w-[2rem] sm:min-w-[2.75rem]";
    const colors = {
      unused: "bg-gray-700 text-white hover:bg-gray-600",
      correct: "bg-emerald-500 text-white",
      present: "bg-amber-500 text-white",
      absent: "bg-gray-600 text-white",
    };
    return `${base} ${sizes} ${colors[status]}`;
  };

  return (
    <div className="w-full max-w-2xl mx-auto px-1 sm:px-2">
      {KEYBOARD_ROWS.map((row, i) => (
        <div key={i} className="flex justify-center gap-0.5 sm:gap-1 my-1">
          {row.map((key) => {
            const status = key.length === 1 ? getKeyStatus(key) : 'unused';
            return (
              <button
                key={key}
                onClick={() => handleKeyClick(key)}
                className={getKeyClasses(status)}
                style={{ flex: key === 'ENTER' ? '1.5' : key === '⌫' ? '1.5' : '1' }}
              >
                {key}
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default Keyboard;